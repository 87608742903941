<template>
  <div class="row m-0 abs">
    <div class="col-10 col-md-6 col-lg-12 p-0 card_item">
      <div class="row mx-0 h-100 justify-content-center">
        <div class="col-12 p-0">
          <div class="row m-0 justify-content-center">
            <div class="col-12 p-0">
              <div class="row m-0 justify-content-center">
                <div class="col p-3">
                </div>
                <div class="col-auto text-center p-0 my-3 mt-4">
                  <Image :imageName="user.imageUrl" width="100" v-if="user.imageUrl" theClass="profile_img" />
                  <IconUser size="size50" v-else />
                </div>
                <div class="col text-end p-3">
                  <ios-close-icon class="d-md-none inline-icon cursor close" @click="$emit('close')" />
                </div>
                <div class="col-12 p-0 mb-1 text-center card_title bold" v-if="user.donorID">
                  DONOR ID: {{user.donorID}}
                </div>
                <div class="col-12 text-center p-0 mb-3 card_heading bold">
                  {{user.firstName}} {{user.lastName}}
                </div>
                <div class="col-3 gold-text border_bottom mb-3">
                </div>
                <div class="col-10 mb-3" v-if="!isUK">
                  <SelectDonor
                    v-if="donateAs && donatingType === 'organisation'"
                    :placeholder="'Donating As:' + donateAs.registeredName"
                    @applyfilter="selectDonor"
                    :title="'Donating As:' + donateAs.registeredName"
                    bg="bg_green"
                    color="white-text"
                    size="small"
                  >
                  <IconChev size="size12" class="rotate" />
                  </SelectDonor>
                  <SelectDonor
                    v-else-if="donateAs && donatingType === 'group'"
                    :placeholder="'Donating As:' + donateAs.donor.firstName + ' ' + donateAs.donor.lastName"
                    @applyfilter="selectDonor"
                    :title="'Donating As:' + donateAs.donor.firstName + ' ' + donateAs.donor.lastName"
                    bg="bg_green"
                    color="white-text"
                    size="small"
                  >
                  <IconChev size="size12" class="rotate" />
                  </SelectDonor>
                  <SelectDonor
                    v-else-if="donateAs && donatingType === 'user'"
                    :placeholder="'Donating As:' + donateAs.firstName + ' ' + donateAs.lastName"
                    @applyfilter="selectDonor"
                    :title="'Donating As:' + donateAs.firstName + ' ' + donateAs.lastName"
                    bg="bg_green"
                    color="white-text"
                    size="small"
                  >
                  <IconChev size="size12" class="rotate" />
                  </SelectDonor>
                  <SelectDonor
                    v-else
                    :placeholder="'Donating As:' + user.firstName + ' ' + user.lastName"
                    @applyfilter="selectDonor"
                    :title="'Donating As:' + user.firstName + ' ' + user.lastName"
                    bg="bg_green"
                    color="white-text"
                    size="small"
                  >
                  <IconChev size="size12" class="rotate" />
                  </SelectDonor>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 p-0 align-self-end">
          <div class="row m-0 background-green pt-3">
            <div class="col-12 col-md-7 pe-md-1 mb-3">
              <div class="row m-0 justify-content-center">
                <div class="col-8 col-md-12 p-0">
                  <Button color="green_light" width="100" btnText="Donor Profile" icon="arrow" @buttonClicked="goProfile" class="px-2">
                    <IconEdit size="size16" color="white" />
                  </Button>
                </div>
              </div>
            </div>
            <div class="col-12 col-md ps-md-1 mb-3">
              <div class="row m-0 justify-content-center">
                <div class="col-8 col-md-12 p-0">
                  <Button color="red" width="100" btnText="Log Out" icon="arrow" @buttonClicked="signout" class="px-2">
                    <IconLogOut size="size16" color="white" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    Image: defineAsyncComponent(() => import('../components/Image.vue')),
    SelectDonor: defineAsyncComponent(() => import('../components/SelectDonor.vue')),
    IconEdit: defineAsyncComponent(() => import('../components/icons/IconEdit.vue')),
    IconLogOut: defineAsyncComponent(() => import('../components/icons/IconLogOut.vue')),
    IconChev: defineAsyncComponent(() => import('../components/icons/IconChev.vue')),
    IconUser: defineAsyncComponent(() => import('../components/icons/IconUser.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'Profile Popup',
  // props: ['user'],
  data () {
    return {
      url: process.env.VUE_APP_URL_BASE,
      donatingID: null
    }
  },
  mounted () {
    if (!this.donateAs && this.user) {
      this.donatingID = this.user.userID
    }
  },
  computed: {
    ...mapGetters([
      'cart', 'user', 'userDonors', 'donateAs'
    ]),
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    donatingType () {
      if (this.donateAs) {
        if (this.donateAs.groupID) {
          return 'group'
        } else if (this.donateAs.organisationID) {
          return 'organisation'
        } else {
          return 'user'
        }
      } else {
        return 'user'
      }
    }
  },
  methods: {
    ...mapActions([
      'logout', 'setDonateAs'
    ]),
    goProfile () {
      this.$emit('close')
      this.$router.push('/profile/0')
    },
    selectDonor (val) {
      const type = val[0].slice(0, 3)
      this.donatingID = parseInt(val[0].slice(3))
      this.setDonateAs(val[1].userDetails)
      if (type === 'org') {
        this.donatingType = 'organisation'
      } else if (type === 'gro') {
        this.donatingType = 'group'
      } else {
        this.donatingType = 'user'
      }
    },
    async signout () {
      this.$emit('close')
      await this.$router.push('/')
      this.logout()
    }
  }
}
</script>

<style scoped>
.abs {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
  justify-content: center;
  align-items: center;
}
.profile_img {
  color: green;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px;
}
.card_item {
  background-color: #fff;
  border-radius: 13px;
  box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
}
.card_title {
  font-size: 13px;
  color: rgb(32, 63, 37);
}
.card_heading {
  font-size: 23px;
  color: rgb(26, 104, 54)
}
.background-green {
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
}
.border_bottom {
  border-bottom: 1px solid;
}
@media (min-width: 992px) {
  .abs {
    position: absolute;
    width: 600%;
    height: inherit;
    left: -250%;
    top: inherit;
    z-index: 2;
    background-color: transparent;
    justify-content: center;
    align-items: center;
  }
  .profile_img {
    color: green;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 0;
  }
  .gold_heading {
    color: var( --gold-color );
    font-family: "quicksand_bold", Sans-serif;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 6.4px;
    text-transform: uppercase;
    text-align: left;
  }
}
</style>
